$co-channel-background-color: var(--pf-v5-c-drawer__content--BackgroundColor);
$co-channel-color: var(--pf-v5-global--disabled-color--100);
$co-channel-current-color: var(--pf-v5-global--primary-color--100);
$co-channel-height: 60px;

.cluster-channel-modal__dropdown .pf-v5-c-dropdown {
  width: 100%;
}

.co-channel {
  align-items: center;
  display: flex;
  font-size: ($font-size-small - 1);
}

.co-channel-line {
  align-items: center;
  display: flex;
  height: $co-channel-height;
  justify-content: center;
  position: relative;
  min-width: 50px;
  width: 100%;

  &:before {
    background-color: $co-channel-color;
    content: '';
    height: 4px;
    position: absolute;
    width: 100%;

    .co-channel-path--current & {
      background-color: $co-channel-current-color;
    }
  }

  &:last-child::after {
    background: transparent;
    border: 8px solid transparent;
    border-left-color: $co-channel-color;
    border-width: 8px 12px;
    content: '';
    position: absolute;
    right: -15px;

    .co-channel-path--current & {
      border-left-color: $co-channel-current-color;
    }

    .co-channel--end-of-life & {
      background-color: $co-channel-current-color;
      border: 0;
      height: 16px;
      right: 0px;
      width: 3px;
    }
  }
}

.co-channel-more-versions {
  background: $co-channel-background-color !important;
  border: 1px solid !important;
  border-radius: 15px !important;
  display: inline-block !important;
  font-size: ($font-size-small - 1) !important;
  font-weight: bold !important;
  justify-content: center;
  padding: var(--pf-v5-global--spacer--xs) var(--pf-v5-global--spacer--sm) !important;
  position: absolute !important;

  &::after {
    display: none;
  }

  &:hover,
  &:focus {
    background: $co-channel-current-color !important;
    border-color: $co-channel-current-color !important;
    color: $co-channel-background-color !important;
  }

  &:focus {
    outline: 0 !important;
  }
}

.co-channel-name {
  color: $co-channel-color;
  padding: 0 10px 0 20px;
  min-width: 150px;
  white-space: nowrap;

  &--current {
    color: inherit;
    padding-top: 20px;
  }
}

.co-channel-path {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;

  &--current {
    margin-top: 20px;
  }
}

.co-channel-start::before {
  position: absolute;
  right: 0;
  width: 25%;
}

.co-channel-switch {
  position: absolute;
  top: -28px;
  width: 100%;
  height: $co-channel-height;
  z-index: 1;

  &::after,
  &::before {
    background: linear-gradient(to left bottom, transparent 50%, $co-channel-color 50%);
    content: '';
    display: block;
    height: $co-channel-height;
    position: absolute;
    right: 0;
    top: -3px;
    width: 50%;
  }

  &::after {
    background: linear-gradient(to left bottom, transparent 50%, $co-channel-background-color 50%);
    right: 3px;
    top: 0;
  }
}

.co-channel-version {
  color: initial;
  display: flex;
  flex-direction: column;
  height: 35px;
  justify-content: flex-end;
  line-height: 1;
  padding: 0 5px;
  position: absolute;
  text-align: center;
  top: -20px;
  word-break: break-word;

  &--current {
    font-weight: var(--pf-v5-global--FontWeight--bold);
  }

  &--update-blocked {
    align-items: flex-end;
    flex-direction: row;
  }

  &__warning-icon {
    flex-shrink: 0;
  }
}

.co-channel-version-dot {
  background: $co-channel-current-color !important;
  border-radius: 16px !important;
  height: 16px !important;
  padding: 0 !important;
  position: absolute;
  width: 16px !important;
  z-index: 2;

  &::after {
    background: $co-channel-background-color !important;
    border: 2px solid $co-channel-background-color !important;
    border-radius: 12px !important;
    bottom: 2px !important;
    content: '';
    left: 2px !important;
    position: absolute;
    right: 2px !important;
    top: 2px !important;
  }

  &--current::after {
    background: transparent !important;
  }

  &:focus {
    outline: 0 !important;
  }

  &--update-blocked {
    background: var(--pf-v5-global--warning-color--100) !important;
  }
}

.co-cluster-settings {
  border-width: 1px;
  max-width: 800px;

  &,
  &__section {
    border-color: var(--pf-v5-global--BorderColor--300);
    border-style: solid;
  }

  &__details {
    padding-right: var(--pf-v5-global--spacer--lg);

    &--status {
      @media (min-width: $screen-xs) {
        max-width: 50%;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;

    @media (max-width: $screen-md) {
      flex-wrap: wrap;
    }
  }

  &__section {
    align-items: center;
    border-width: 1px 0 0 1px;
    flex: 1 1 auto;
    padding: var(--pf-v5-global--spacer--lg) 0 var(--pf-v5-global--spacer--lg)
      var(--pf-v5-global--spacer--lg);
    margin-left: -1px;
    margin-top: -1px;

    &--current {
      flex-grow: 0;
    }
  }

  &__updates-graph {
    padding: 0 var(--pf-v5-global--spacer--lg) 0 0;
    @media (max-width: 400px) {
      display: none;
    }
  }

  &__updates-group {
    margin: 0 0 var(--pf-v5-global--spacer--lg) 0;

    &--divided {
      border-top: 1px solid var(--pf-v5-global--BorderColor--300);
      padding-top: var(--pf-v5-global--spacer--lg);

      // only add border and padding to first instance
      & ~ & {
        border-top: 0;
        padding-top: 0;
      }
    }
  }

  &__updates-progress:not(:empty) {
    padding: var(--pf-v5-global--spacer--sm) var(--pf-v5-global--spacer--lg) 0 0;
  }

  &__updates-type {
    margin: 0 0 var(--pf-v5-global--spacer--xs) 0;
  }
}

.co-update-status {
  padding-bottom: 5px;
}

.co-cluster-paragraph {
  margin-bottom: var(--pf-v5-c-content--MarginBottom);
}
