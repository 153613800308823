.co-utilization-card__body {
  padding: 0;
}

.co-utilization-card__item .co-u-hidden {
  display: none;
}

.co-utilization-card__item .co-utilization-card__item-chart.co-utilization-card__item-chart--times {
  padding: var(--pf-v5-global--spacer--sm) var(--pf-v5-global--spacer--lg) var(--pf-v5-global--spacer--sm) var(--pf-v5-global--spacer--md);
}

.co-utilization-card__item .co-utilization-card__item-chart.co-utilization-card__item-chart--times svg {
  overflow: visible;
}

.co-utilization-card__item-header {
  border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
}

.co-utilization-card__item .pf-v5-c-title {
  margin-bottom: 0;
}

.co-utilization-card__item>* {
  padding: var(--pf-v5-global--spacer--md);
}

.co-utilization-card__item-chart .graph-wrapper {
  margin: 0;
  padding: 0;
}

.co-utilization-card__item-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.co-utilization-card__item-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.co-utilization-card__item-section-multiline {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.co-utilization-card__item-text {
  color: var(--pf-v5-global--Color--200);
  font-size: var(--pf-v5-global--FontSize--sm);
}

.co-utilization-card__item-icon {
  margin-right: var(--pf-v5-global--spacer--sm);
}

@media screen and (min-width: 1200px) {
  .co-utilization-card__item {
    display: flex;
    justify-content: space-between;
  }

  .co-utilization-card__item .co-u-visible-on-xl {
    display: flex;
  }

  .co-utilization-card__item .co-utilization-card__item-chart.co-utilization-card__item-chart--times {
    padding-bottom: 0;
  }

  .co-utilization-card__item>* {
    flex: 1 50%;
  }

  .co-utilization-card__item>*:nth-child(even) {
    align-items: center;
    padding: 0 var(--pf-v5-global--spacer--lg) 0 var(--pf-v5-global--spacer--md);
  }

  .co-utilization-card__item>*:nth-child(odd) {
    align-items: flex-start;
    padding: var(--pf-v5-global--spacer--sm) var(--pf-v5-global--spacer--lg);
  }
}

