.ocs-getting-started-expandable-grid {
  --min-column-width: 220px;
}

.ocs-getting-started-expandable-grid__header.pf-v5-c-card__header {
  padding-right: var(--pf-v5-global--spacer--sm);
}

.ocs-getting-started-expandable-grid__tooltip {
  white-space: pre-line;
}

.ocs-getting-started-expandable-grid__tooltip-icon {
  margin-left: var(--pf-v5-global--spacer--sm);
}

.ocs-getting-started-expandable-grid__content.pf-v5-c-card__body {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--min-column-width), 1fr));
  padding: 0 0 calc(var(--pf-v5-c-card--child--PaddingBottom)/2) 0;
  overflow: hidden;
  padding-top: 0 !important;
}

.ocs-getting-started-expandable-grid__content.pf-v5-c-card__body>.pf-v5-l-flex.pf-m-grow.pf-m-column {
  border-right: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  margin-right: calc(-1*var(--pf-v5-global--BorderWidth--sm));
  padding-top: calc(var(--pf-v5-c-card--first-child--PaddingTop)/2);
  padding-bottom: calc(var(--pf-v5-c-card--child--PaddingBottom)/2);
  padding-left: var(--pf-v5-c-card--child--PaddingLeft);
  padding-right: var(--pf-v5-c-card--child--PaddingRight);
}

.ocs-getting-started-expandable-section {
  background-color: var(--pf-v5-global--BackgroundColor--100);
}

.ocs-getting-started-expandable-section__toggle-text {
  color: var(--pf-v5-global--Color--dark-100);
  font-size: var(--pf-v5-global--icon--FontSize--md);
  font-weight: var(--pf-v5-global--FontWeight--bold);
}

.ocs-getting-started-expandable-section__toggle-text.is-dark {
  color: var(--pf-v5-global--Color--light-100);
}

.pf-v5-c-expandable-section__toggle {
  align-items: center;
}

.pf-v5-c-expandable-section__content {
  padding: 0 !important;
}

